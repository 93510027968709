import { Controller } from '@hotwired/stimulus';
import { Bloodhound } from '../../vendor/corejs-typeahead';
// import BoardLinks from '../../utils/board-links';

export default class extends Controller {
  static targets = ['typeahead'];

  isLoading = {
    cities: false,
    hoods: false,
    'hoods-schools': false,
    schools: false,
    listings: false,
    reportsuggestion: false,
  };

  connect() {
    super.connect();

    if ($(this.typeaheadTarget).attr('data-initialized') == null) {
      this.entitiesForRemote = this.typeaheadTarget.dataset.entities;
      this.linkPrefix = this.typeaheadTarget.dataset.linkPrefix;
      this.keyPrefix = this.typeaheadTarget.dataset.keyPrefix;
      this.board = this.typeaheadTarget.dataset.board;
      this.hoodsNoGuide = JSON.parse(this.typeaheadTarget.dataset.hoodsNoGuide);
      this.reportsLink = this.typeaheadTarget.dataset.reportsLink;
      this.authenticated = this.typeaheadTarget.dataset.authenticated === "true";
      this.autofocus = $(this.typeaheadTarget).attr('autofocus');

      var entities = this.entitiesForRemote.split(',');

      var typeaheadOptions = [
        {
          highlight: true,
          minLength: 1,
          hint: true,
          classNames: {
            menu: 'tt-menu dropdown-menu show !hqtw-px-4 !hqtw-pt-1 !hqtw-pb-3 md:!hqtw-min-w-[400px]',
            dataset: 'tt-dataset inner show',
          },
        },
      ];

      this.initializeCityDataset();

      typeaheadOptions.push({
        name: 'cities',
        display: 'name',
        source: this.cityDataset,
        templates: {
          header:
            '<h5 class="hqtw-text-base hqtw-font-semibold hqtw-mt-3">Cities</h5>',
          // See event handler for `typeahead:asyncreceive` below.
          notFound:
            '<span class="hqtw-text-grey hqtw-text-base hqtw-pl-2" style="display: none;" data-hq-no-results>No results.</span>',
        },
      });

      if (entities.includes('neighbourhoods')) {
        this.initializeNeighbourhoodDataset();
        typeaheadOptions.push({
          name: 'hoods',
          display: 'name',
          source: this.neighbourhoodDataset,
          templates: {
            header:
              '<h5 class="hqtw-text-base hqtw-font-semibold hqtw-mt-3">Neighbourhood Guides</h5>',
            suggestion: (result) =>
              `<div>${result.name}, ${result.city.name}</div>`,
          },
        });
        typeaheadOptions.push({
          name: 'hoods-schools',
          display: 'name',
          source: this.neighbourhoodDataset,
          templates: {
            header:
              '<h5 class="hqtw-text-base hqtw-font-semibold hqtw-mt-3">Neighbourhood School Guides</h5>',
            suggestion: (result) =>
              `<div>${result.name} Schools,  ${result.city.name}</div>`,
          },
        });
      }

      if (entities.includes('schools')) {
        this.initializeSchoolDataset();
        typeaheadOptions.push({
          name: 'schools',
          display: 'name',
          source: this.schoolDataset,
          templates: {
            header:
              '<h5 class="hqtw-text-base hqtw-font-semibold hqtw-mt-3">Schools</h5>',
            pending:
              '<h5 class="hqtw-text-base hqtw-font-semibold hqtw-mt-3" data-hq-loading-message>Searching...</h5>',
            suggestion: (result) =>
              `<div>${result.name}, ${result.neighbourhood.name}</div>`,
          },
        });
      }

      if (entities.includes('listings')) {
        this.initializeListingDataset();

        this.map = new google.maps.Map(
          document.getElementById('search-component-map'),
          {},
        );
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.placesService = new google.maps.places.PlacesService(this.map);
        this.sessionToken = new google.maps.places.AutocompleteSessionToken();

        typeaheadOptions.push({
          name: 'listings',
          display: 'name',
          source: this.listingDataset,
          templates: {
            header:
              '<h5 class="hqtw-text-base hqtw-font-semibold hqtw-mt-3">Listings</h5>',
            suggestion: (result) => `<div>${result.name}</div>`,
          },
        });

        typeaheadOptions.splice(1, 0, {
          name: 'reportsuggestion',
          display: 'name',
          source: (query, resolveSync, resolveAsync) => {
            // Don't fire requests to Google if they don't include a street number.
            if (!/\d/.test(query)) {
              return resolveAsync([]);
            }

            let country;

            if (window.hq_locale === "en") {
              country = "ca";
            } else if (window.hq_locale === "en-US") {
              country = "us";
            }

            const autocompleteRequest = {
              input: query,
              componentRestrictions: { country: [country] },
              types: ['address'],
              sessionToken: this.sessionToken,
            };

            const result = [];

            this.autocompleteService.getPlacePredictions(
              autocompleteRequest,
              (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  for (let i = 0; i < results?.length; i++) {
                    const item = results[i];

                    // We need `description` to pass to reports app, and it should include a number.
                    if (!item?.description || !/\d/.test(item.description)) {
                      continue;
                    }

                    result.push(item);

                    // If this is the last autocomplete result, resolve.
                    if (i === results.length - 1) {
                      resolveAsync(result);
                    }
                  }
                } else {
                  // No results.
                  resolveAsync(result);
                }
              },
            );
          },
          templates: {
            header:
              '<h5 class="hqtw-text-base hqtw-font-semibold hqtw-mt-3">Address Reports</h5>',
            suggestion: (result) => `<div>${result.description}</div>`,
          },
        });
      }

      const typeaheadInstance = $(this.typeaheadTarget)
        .typeahead(...typeaheadOptions)
        .on('typeahead:select', (ev, suggestion, dataset) => {
          if (dataset === 'cities') {
            window.location.href = this.buildKeyedUrl(suggestion.slug);
          } else if (dataset === 'hoods') {
            window.location.href = this.buildKeyedUrl(
              `${suggestion.city.slug}/${suggestion.slug}`,
            );
          } else if (dataset === 'hoods-schools') {
            window.location.href = this.buildKeyedUrl(
              `schools/${suggestion.city.slug}/${suggestion.slug}`,
              false,
            );
          } else if (dataset === 'schools') {
            window.location.href = this.buildKeyedUrl(
              `schools/${suggestion.city.slug}/${suggestion.neighbourhood.slug}/${suggestion.slug}`,
              false,
            );
          } else if (dataset === 'listings') {
            // window.open(BoardLinks[this.board].getSingleListingLink(suggestion.id), '_blank');
            window.open(
              `/external_link/trrebca/listing?mls_number=${suggestion.id}`,
              '_blank',
            );
          } else if (dataset === 'reportsuggestion') {
            // Enrich selection with a call to .getDetails() so we can hand off to the reports app.
            const detailRequest = {
              placeId: suggestion.place_id,
              fields: ['geometry'],
              sessionToken: this.sessionToken,
            };

            this.placesService.getDetails(
              detailRequest,
              (detailResult, detailStatus) => {
                if (
                  detailStatus === google.maps.places.PlacesServiceStatus.OK
                ) {
                  const placeDetails = {
                    ...suggestion,
                    geometry: {
                      lat: detailResult.geometry.location.lat(),
                      lng: detailResult.geometry.location.lng(),
                    },
                  };

                  let url = `https://app.hoodq.com/products?address=${placeDetails.description}`;

                  if (this.keyPrefix) {
                    url = `${url}&brand_slug=${this.keyPrefix}`;
                  }

                  if (this.authenticated) {
                    url = `${url}&auth=true`;
                  }

                  window.location.href = url;
                } else {
                  // Gulp
                }
              },
            );
          }
        })
        // Update internal loading state
        .on('typeahead:asyncrequest', (event, name, query) => {
          // Hack due to `hoods` not firing `asyncreceive` in some cases.
          if (name === 'hoods') {
            return;
          }

          this.isLoading[name] = true;

          $('[data-hq-loading-message]').show();
        })
        .on('typeahead:asyncreceive', (event, name, query) => {
          this.isLoading[name] = false;

          // Evaluate whether the loader is shown.
          const isLoading = Object.values(this.isLoading).includes(true);

          if (!isLoading) {
            $('[data-hq-loading-message]').hide();

            // Evaluate whether the no results message is shown.
            if ($('.tt-suggestion').length) {
              $('[data-hq-no-results]').hide();
            } else {
              $('[data-hq-no-results]').show();
            }
          }
        });

      if (this.autofocus) {
        typeaheadInstance.focus();
      }
    }

    // Set data attribute on element
    $(this.typeaheadTarget).attr('data-initialized', '');
  }

  buildKeyedUrl(path, usePrefix = true) {
    let chunks = [];

    if (this.keyPrefix) {
      chunks.push(this.keyPrefix);
    }

    if (this.linkPrefix && usePrefix) {
      chunks.push(this.linkPrefix.replace(/\//, ''));
    }

    chunks.push(path);

    return `/${chunks.join('/')}`;
  }

  disconnect() {
    $(this.typeaheadTarget).typeahead('destroy');
  }

  initializeCityDataset() {
    this.cityDataset = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: '/cities.json?v=1',
      identify: function (obj) {
        return obj.name;
      },
    });
  }

  initializeNeighbourhoodDataset() {
    this.neighbourhoodDataset = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.nonword('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: {
        url: '/hoods.json?v=1',
      },
      remote: {
        url: `/search?entities=${this.entitiesForRemote}&term=%QUERY`,
        wildcard: '%QUERY',
        filter: (resp) => {
          return resp.results.filter(
            (r) =>
              r.type === 'neighbourhoods' &&
              this.hoodsNoGuide.includes(r.slug) === false,
          );
        },
      },
      identify: function (obj) {
        return obj?.name;
      },
    });
  }

  initializeSchoolDataset() {
    this.schoolDataset = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `/search?entities=${this.entitiesForRemote}&term=%QUERY`,
        wildcard: '%QUERY',
        filter: (resp) => resp.results.filter((r) => r.type === 'schools'),
      },
      identify: function (obj) {
        return obj.name;
      },
    });
  }

  initializeListingDataset() {
    this.listingDataset = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // prefetch: {
      //   url: "/listings.json" //Need proper prefetch url
      // },
      remote: {
        url: `/search?entities=${this.entitiesForRemote}&term=%QUERY`,
        wildcard: '%QUERY',
        cache: false,
        filter: (resp) => resp.results.filter((r) => r.type === 'listings'),
      },
      identify: function (obj) {
        return obj.id;
      },
    });
  }
}
