import { Controller } from '@hotwired/stimulus';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default class extends Controller {
  static targets = ['map']

  polygonColor = '#00AEEF';
  polygonHoverColor = '#ECB020';

  connect() {
    $('.selectpicker').selectpicker();

    this.settings = {
      tileLayer: {
        tiles:
            'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
      },
      dragging: $(window).width() > 700,
      tap: $(window).width() > 700,
    };

    this.featureGroup = null;
    this.markersGroup = [];
    this.initializeMap();
  }

  initializeMap() {
    this.map = L.map(this.mapTarget, {
      zoom: 14,
      scrollWheelZoom: false,
      dragging: this.settings.dragging,
      tap: this.settings.tap,
    });

    this.map.once('focus', function () {
      this.map.scrollWheelZoom.enable();
    });

    L.tileLayer(this.settings.tileLayer.tiles, {
      attribution: this.settings.tileLayer.attribution,
      minZoom: 1,
      maxZoom: 19,
    }).addTo(this.map);

    L.Map.include({
      getMarkerById: function (id) {
        var marker = null;
        this.eachLayer(function (layer) {
          if (layer instanceof L.Polygon) {
            if (layer.options.properties.id === id) {
              marker = layer;
            }
          }
        });
        return marker;
      },
    });

    this.markersGroup = [];
  }

  updateFeatures(features) {
    if (this.featureGroup) {
      this.featureGroup.removeFrom(this.map);
    }

    this.markersGroup.forEach(layer => layer.removeFrom(this.map));
    this.featureGroup = null;
    this.markersGroup = [];

    L.geoJSON(features, {
      onEachFeature: this.onEachFeature.bind(this),
      color: this.polygonColor,
      width: 1,
    }).addTo(this.map);

    if (this.markersGroup) {
      this.featureGroup = new L.featureGroup(this.markersGroup);
      this.map.fitBounds(this.featureGroup.getBounds());
    }
  }

  onEachFeature(feature, layer) {
    layer.on({
      mouseover: (e) => {
        this.highlight(e.target);
      },
      mouseout: (e) => {
        this.reset(e.target);
      },
    });

    layer.options.properties = feature.properties;

    layer.bindPopup(this.getPopupContent(feature.properties), {
      minWidth: 600,
      maxWidth: 600,
      className: 'map-custom-popup',
    });

    layer.bindTooltip(
        `<div id="customTooltip-${feature.properties.id}">${feature.properties.name}</div>`,
        {
          direction: 'top',
          permanent: false,
          opacity: 1,
          interactive: true,
          className: 'map-custom-tooltip',
        },
    );

    this.markersGroup.push(layer);
  }

  getPopupContent(properties) {
    const popupEl = document.getElementById(`popup-content-${properties.id}`);
    return popupEl.innerHTML;
  }

  getMarkerById(markerId) {
    return this.map.getMarkerById(markerId);
  }

  highlight(marker) {
    marker.setStyle({ color: this.polygonHoverColor });
  }

  reset(marker) {
    marker.setStyle({ color: this.polygonColor });
  }

}