import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['saveSuccess', 'saveButton'];

  connect() {
    super.connect();
  }

  save() {
    $.ajax({
      url: `/insights/${window.insightsId}/commit`,
      method: 'PUT',
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
      },
    })
      .then(() => {
        $(this.saveButtonTarget).hide();
        $(this.saveSuccessTarget).show();
      })
      .fail((response) => {
        $(this.saveSuccessTarget).text(response.responseJSON.error).show();
        $(this.saveButtonTarget).hide();
      });
  }
}
