import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['map'];

  connect() {
    super.connect();

    this.markersGroup = [];

    this.settings = {
      tileLayer: {
        tiles:
          'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
      },
      dragging: $(window).width() > 700,
      tap: $(window).width() > 700,
    };

    this.initializeMap();

    this.features = JSON.parse(this.element.dataset.features);
    this.updateMapFeatures();

    if (this.element.dataset.enableSdk) {
      this.initializeSdk();
    }
  }

  initializeMap() {
    this.map = L.map(this.mapTarget, {
      zoom: 14,
      scrollWheelZoom: false,
      dragging: this.settings.dragging,
      tap: this.settings.tap,
    });

    this.map.once('focus', () => {
      this.map.scrollWheelZoom.enable();
    });

    L.tileLayer(this.settings.tileLayer.tiles, {
      attribution: this.settings.tileLayer.attribution,
      minZoom: 1,
      maxZoom: 19,
    }).addTo(this.map);
  }

  updateMapFeatures() {
    const features = L.geoJSON(this.features, {
      color: this.polygonColor,
      width: 1,
    }).addTo(this.map);

    this.map.fitBounds(features.getBounds());
  }

  initializeSdk() {
    if (!window.HQ) {
      setTimeout(this.initializeSdk.bind(this), 500);
      return;
    }

    console.log('attempting to load map');

    HQ.waitFor('Map').then((Map) => {
      console.log('map loaded');
      this.hqMap = new Map({
        map: this.map,
        mapType: 'leaflet',
        mapOptions: {
          autoZoomAllMarkers: true,
        },
        disableFilters: true,
        defaultFilters: {
          schools: {
            type: ['public', 'catholic'],
            language: ['english'],
            grade_level: [],
            score: '',
          },
        },
        neighbourhood: {
          hood: this.features.properties.slug,
          city: this.features.properties.city.slug,
        },
      });
    });
  }
}
