import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'snap', 'snapValueFrom', 'snapValueTo', 'snapInputFrom', 'snapInputTo' ];
  static values = {
    step: Number,
    min: Number,
    max: Number
  }

  connect() {
    noUiSlider.create(this.snapTarget, {
      start: [this.minValue, this.maxValue],
      snap: false,
      connect: true,
      step: this.stepValue,
      range: {
        min: this.minValue,
        max: this.maxValue
      }
    });

    const snapValues = [
        this.snapValueFromTarget, this.snapValueToTarget
    ]

    const inputValues = [
        this.snapInputFromTarget, this.snapInputToTarget
    ]

    this.snapTarget.noUiSlider.on('update', (values, handle, unencoded) => {
      inputValues[handle].value = parseInt(values[handle]);
      snapValues[handle].innerHTML = parseInt(values[handle]).toLocaleString();
    })
  }
}