import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    features: Object
  }

  static targets = ['scroll', 'sortForm']

  static outlets = ['explore-map']

  connect() {
    window.scrollTo(0, 0);
    this.exploreMapOutlet.updateFeatures(this.featuresValue);
  }

  cardMouseEnter(event) {
    const marker = this.exploreMapOutlet.getMarkerById(event.target.dataset.markerId);
    this.exploreMapOutlet.highlight(marker);
  }

  cardMouseLeave(event) {
    const marker = this.exploreMapOutlet.getMarkerById(event.target.dataset.markerId);
    this.exploreMapOutlet.reset(marker);
  }

  sort(event) {
    this.sortFormTarget.requestSubmit();
  }
}