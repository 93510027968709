import { Controller } from '@hotwired/stimulus';
import Swiper, { Pagination, Navigation, Lazy } from 'swiper';

Swiper.use([Pagination, Navigation, Lazy]);

export default class extends Controller {
  connect() {
    super.connect();

    this.slider = new Swiper(this.element, {
      preloadImages: false,
      lazy: { checkInView: true },
      watchSlidesVisibility: true,
      slidesPerView: 4,
      spaceBetween: 10,
      loop: true,
      roundLengths: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        991: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
      // Pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}
